import * as React from 'react';
import { CategoryWrapper, CategoryButton } from './blog-post-filters.style';
import { SearchBox } from '../search-box/search-box';


const BlogPostFilters = ({ category, searchField, onSearchChange, handleCategoryClick }) => {
    return ( 
        <>
            <CategoryWrapper>
                <CategoryButton selected={!category.length} value='all' onClick={handleCategoryClick}>ALL</CategoryButton>
                <CategoryButton selected={category.includes('mind')} value='mind' onClick={handleCategoryClick}>MIND</CategoryButton> 
                <CategoryButton selected={category.includes('body')} value='body' onClick={handleCategoryClick}>BODY</CategoryButton>
                <CategoryButton selected={category.includes('food')} value='food' onClick={handleCategoryClick}>FOOD</CategoryButton>
            </CategoryWrapper> 
            
            <SearchBox type='search' placeholder="search" value={searchField} onChange={onSearchChange} />
        </>
     );
}
 
export default BlogPostFilters;