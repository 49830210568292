import * as React from 'react';
import { useBlogPostData } from '../hooks/use-blog-posts-data';
import Layout from '../components/layout/layout';
import BlogPostFilters from '../components/blog-post-filters/blog-post-filters';
import BlogPostGrid from '../components/blog-post-grid/blog-post-grid';

const BlogPage = () => {
  
  const [category, setCategory] = React.useState('');
  const [searchField, setSearchField] = React.useState('');
  const blogPostsArray = useBlogPostData(); //static query hook - hooks folder

  const filteredPosts = blogPostsArray
    .filter(
      post => post.frontmatter.title.toLowerCase().includes(searchField.toLowerCase()) || 
              post.frontmatter.description.toLowerCase().includes(searchField.toLowerCase()))
    .filter(post => post.frontmatter.category.toLowerCase().includes(category));

  const onSearchChange = (event) => {
    const searchInput = event.target.value;
    setSearchField(searchInput);
  }

  const handleCategoryClick = (event) => {
    const button = event.target;
    const categoryValue = event.target.value;
    button.setAttribute('selected', true)
    setSearchField('');
    
    if (categoryValue === 'all') {
        setCategory('');
    } else
    setCategory(categoryValue);
  }

  return (
    
    <Layout pageTitle="All the Posts" showNav={true}>
      <h1>All the Posts</h1>
      <BlogPostFilters 
        category={category}
        searchField={searchField}
        onSearchChange={onSearchChange}
        handleCategoryClick={handleCategoryClick}  
      />
      <BlogPostGrid filteredPosts={filteredPosts} heading=""/>
    </Layout>
  )
}

export default BlogPage