import styled from "@emotion/styled";
import { Button } from '../../components/button/button';

export const CategoryWrapper = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0;
  list-style: none;
  padding: 0;
`

export const CategoryButton = styled(Button)`
  margin: 0 1rem;
  font-size: 1rem;
  border-radius: 24px;
  border: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
  color: ${({ selected, theme }) => selected ? theme.colors.white : theme.colors.secondary};
  background-color: ${({ selected, theme }) => selected ? theme.colors.secondary : 'transparent'};

  &:hover {
    transform: none;
    box-shadow: ${({ theme }) => `0px 0px 20px ${theme.colors.secondary}`};
  }

  @media screen and (max-width: 650px) {
    font-size: 0.75rem;
    margin: 0 0.75rem;
  }
`